.funnelback {
  /*!
   * Bootstrap v4.0.0-beta.2 (https://getbootstrap.com)
   * Copyright 2011-2017 The Bootstrap Authors
   * Copyright 2011-2017 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */

  @import "functions";
  @import "variables";
  @import "mixins";
  @import "root";
  @import "print";
  @import "reboot";
  @import "type";
  @import "images";
  @import "code";
  @import "grid";
  @import "tables";
  @import "forms";
  @import "buttons";
  @import "transitions";
  @import "dropdown";
  @import "button-group";
  @import "input-group";
  @import "custom-forms";
  @import "nav";
  @import "navbar";
  @import "card";
  @import "breadcrumb";
  @import "pagination";
  @import "badge";
  @import "jumbotron";
  @import "alert";
  @import "progress";
  @import "media";
  @import "list-group";
  @import "close";
  @import "modal";
  @import "tooltip";
  @import "popover";
  @import "carousel";
  @import "utilities";
}
